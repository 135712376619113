<template>
    <div class="account-content mb-0">
        <div class="section-title-wrap mb-0 d-flex justify-content-between align-items-start">
            <div class="section-title margin-deliver">{{ $t("Payment History")}}</div>
        </div>

        <PaymentHistoryLoader v-if="isContentLoading"></PaymentHistoryLoader>

        <template v-else>
            <h4 class="empty-message" v-if="isEmpty">{{ $t("no payment history found")}}</h4>
            <!-- data table -->
            <div class="data-table history-table" v-if="!isEmpty">
                <div class="table-wrap">
                    <div class="table">
                        <div class="table__row table__head">
                            <div class="table-cell text-center">{{ $t("No")}}</div>
                            <div class="table-cell">{{ $t("Trans ID")}}</div>
                            <div class="table-cell">{{ $t("Details")}}</div>
                            <div class="table-cell">{{ $t("Type")}}</div>
                            <div class="table-cell">{{ $t("Coupon")}}</div>
                            <div class="table-cell">{{ $t("Total")}}</div>
                            <div class="table-cell">{{ $t("Date")}}</div>
                            <div class="table-cell text-center">{{ $t("Status")}}</div>
                            <div class="table-cell text-center">{{ $t("Actions")}}</div>
                        </div>
                        <div class="table__row" v-for="(transaction, key) in transactions">
                            <div class="table-cell pay-counter">{{(key+1)}}</div>
                            <div class="table-cell trans-ID">{{transaction.trans_id}}</div>
                            <div class="table-cell pay-Details">{{transaction.details}}</div>
                            <div class="table-cell text-center pay-type">{{transaction.type}}</div>
                            <div class="table-cell text-center pay-type">
                                <span v-if="transaction.coupon && transaction.hasOwnProperty('coupon.code') && transaction.coupon.code.length>0" class="color-warning" style="font-size: 12px">{{transaction.coupon.name}} ({{transaction.coupon.code}})</span>
                            </div>
                            <div class="table-cell text-right pay-amount">
                                <p>{{transaction.total}}</p>
                            </div>
                            <div class="table-cell pay-time">{{transaction.date}}</div>
                            <div class="table-cell pay-status">
                                <div class="semi-button h-modified semi-button-success b-radius-50" v-if="(transaction.isPayable && transaction.paid)">{{$t('Paid')}}</div>
                                <div class="semi-button h-modified semi-button-warning b-radius-50" v-if="(transaction.isPayable && !transaction.paid)">{{$t('Unpaid')}}</div>
                            </div>
                            <div class="table-cell pay-action">
                              <button v-if="(transaction.isPayable && transaction.action.link)" @click="getActionLink(transaction.id)" class="semi-button h-modified info-button b-radius-50">{{transaction.action.title}}</button>
                            </div>
                        </div>
                    </div>

                    <pagination :pagination="paginationData" @paginate="getPaymentHistory"></pagination>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import client from "../../app/api/Client";
    const PaymentHistoryLoader = () => import("../../components/_loaders/PaymentHistoryLoader.vue");
    const Pagination = () => import("../../components/pagination/Pagination.vue");

    export default {
        components: {
            Pagination,
            PaymentHistoryLoader
        },
        data() {
            return {
                transactions: [],
                isContentLoading: false,
                paginationData: {},
            }
        },

        computed: {
            isEmpty() {
                return !this.transactions.length;
            }
        },

        methods: {
            async getPaymentHistory(page) {
                this.isContentLoading = true;
                try {
                    if(typeof page === 'undefined') page = 1;
                    let {data : {data}} = await client().get(`/my-account/payment-history?page=${page}`);
                    this.transactions = data.data;
                    _.unset(data, 'data');
                    this.paginationData = data;
                } catch (e) {
                }
                this.isContentLoading = false;
            },

            async getActionLink(transactionId) {
                try {
                    let {data: {data}} = await client().get(`/my-account/payment-action/${transactionId}`);
                    if (data.url !== '#') {
                        window.open(data.url, '_blank');
                    }
                } catch (e) {
                }
            }
        },

        async mounted() {
            await this.getPaymentHistory();
        }
    }
</script>

<style scoped>
.history-table .table .table-cell.pay-status *,
    .history-table .table .table-cell.pay-action a {
        word-break: unset !important;
    }
</style>